<template>
    <div class="reg_content">

        <div class="reg_panel">
            <router-link tag="div" to="/main"  style="width: 100%;text-align: right;padding: 5px;font-size: 22px;position: absolute;color: #fff9ff;cursor: pointer">
                <i class="fa fa-window-close"></i></router-link>
            <div class="crt_panel">
                <img src="../../assets/images/logo/ptn/logo01.png" alt="" style="width: 100%">
            </div>
            <div class="form mt">
                <div class="fitem">
                    <label><i class="fa fa-user-circle"></i> 아이디</label>
                    <input v-model="user.username" type="text" placeholder="6-15자리 영문,숫자 조합">
                </div>
                <div class="fitem">
                    <label><i class="fa fa-user-circle"></i> 닉네임</label>
                    <input v-model="user.nickname" type="text" placeholder="3-6자리 한글">
                </div>
            </div>
            <div class="form mt">
                <div class="fitem">
                    <label><i class="fa fa-lock"></i> 비밀번호</label>
                    <input v-model="user.passwd" type="password" placeholder="4-20자리 영문,숫자">
                </div>
                <div class="fitem">
                    <label><i class="fa fa-lock"></i> 비밀번호 확인</label>
                    <input v-model="user.confirm_password" type="password" placeholder="비밀번호 확인">
                </div>
            </div>
            <div class="form mt">
                <div class="fitem">
                    <label>생일 년/월/일</label>
                    <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: nowrap">
                        <select v-model="user.byear">
                            <option :value="null">=년도=</option>
                            <option :value="item" v-for="item in syear">{{item}}</option>
                        </select>
                        <select v-model="user.bmonth">
                            <option :value="null">=월=</option>
                            <option :value="item" v-for="item in smonth">{{item}}</option>
                        </select>
                        <select v-model="user.bday">
                            <option :value="null">=일=</option>
                            <option :value="item" v-for="item in sday">{{item}}</option>
                        </select>
                    </div>

                </div>
                <div class="fitem">
                    <label>통신사</label>
                    <select v-model="user.telcomp">
                        <option :value="null">- - - 통신사선택 - - -</option>
                        <option value="SKT">SKT</option>
                        <option value="LG">LG</option>
                        <option value="KT">KT</option>
                        <option value="알뜰SKT">알뜰SKT</option>
                        <option value="알뜰LG">알뜰LG</option>
                        <option value="알뜰KT">알뜰KT</option>
                    </select>
                </div>
            </div>

            <div class="form mt">
                <div class="fitem">
                    <label><i class="fa fa-phone"></i> 휴대폰</label>
                    <input v-model="user.phone" type="text" placeholder="`-` 없이 숫자만 입력">
                </div>
                <div class="fitem">
                    <label><i class="fa fa-check-square-o"></i> </label>
                    <button class="btn-recivehpnecode" @click="getVerifyPhoneCode">인증받기</button>
                </div>


            </div>
          <div class="form mt">
            <div class="fitem">
              <label><i class="fa fa-phone"></i> 인증코드</label>
              <input v-model="user.verificationCode" type="text" placeholder="휴대폰 인증코드">
            </div>
            <div class="fitem">
              <label><i class="fa fa-bank"></i> 은행선택</label>
              <select style="font-size: 13px;padding: 3px 0;width: 95%" v-model="user.bank">
                <option value="">- - - 은행선택 - - -</option>
                <!--                        <option value="카카오뱅크">카카오뱅크</option>-->
                <!--                        <option value="케이뱅크">케이뱅크</option>-->
                <option value="신한">신한은행</option>
                <option value="국민">국민은행</option>
                <option value="농협">농협</option>
                <option value="우리">우리은행</option>
                <!--                        <option value="하나">하나은행</option>-->
                <option value="기업">기업은행</option>
                <option value="우체국">우체국</option>
                <option value="대구">대구은행</option>
                <option value="경남">경남은행</option>
                <option value="광주">광주은행</option>
                <option value="부산">부산은행</option>
                <option value="산림조합">산림조합</option>
                <option value="신협">신협은행</option>
                <option value="전북">전북은행</option>
                <option value="제주">제주은행</option>
                <option value="씨티">씨티은행</option>
                <option value="새마을">새마을금고</option>
                <option value="SC제일은행">SC제일은행</option>
                <option value="기타">기타</option>
              </select>
            </div>

          </div>
            <div class="form mt">
                <div class="fitem">
                    <label><i class="fa fa-id-card"></i> 계좌번호</label>
                    <input v-model="user.acNo" type="text" placeholder="`-` 없이 숫자만 입력">
                </div>
                <div class="fitem">
                    <label><i class="fa fa-user"></i> 예금주</label>
                    <input v-model="user.beneficiary" type="text" placeholder="환전 예금주명">
                </div>
            </div>
            <div class="form mt">
                <div class="fitem">
                    <input type="text" v-model="user.code"  placeholder="총판,추천인 코드" readonly>
                </div>
                <div class="fitem">
                    <button @click="doRegister" class="btn-register">확인</button>
                </div>
            </div>
            <div class="err" v-show="erm != null">
                <i class="fa fa-warning"></i> {{erm}}
            </div>


        </div>

    </div>


</template>

<script>
    import {
        checkUserName,
        checkNickName,
        getVerificationCode,
        register,
        getSiteStatus, logout, getVerificationImgCode
    } from "../../network/userRequest";
    import {loginRegisterMixin} from "../../common/mixin";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";

    export default {
        name: "register",
        components: {FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    verificationCode: '',
                    bank: '',
                    beneficiary: '',
                    acNo: '',
                    extractPasswd: '',
                    code: this.$store.state.userinsertagentcode,
                    telcomp: null,
                    byear: null,
                    bmonth: null,
                    bday: null,
                },
                error: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    verificationCode: '',
                    bank: '',
                    beneficiary: '',
                    acNo: '',
                    extractPasswd: '',
                    code: ''
                },
                status: {
                    username: false,
                    passwd: false,
                    confirm_password: false,
                    nickname: false,
                    phone: false,
                    verificationCode: false,
                    bank: false,
                    beneficiary: false,
                    acNo: false,
                    extractPasswd: false,
                    code: false
                },
                isUsePhoneCode: false,
                erm: null,
                syear: [],
                smonth: [],
                sday: [],
            }
        },
        methods: {
            doRegister() {
                if (!this.checkConfirmPassword()) {
                    this.erm = '입력하신 비밀번호가 동일하지 않습니다'
                    return false;
                }
                this.$store.commit(RECEIVE_SHOW_LOADING)
                register(this.user).then(res => {
                    if (res.data.success) {
                        this.$swal({
                            title: '회원가입을 축하드립니다.관리자 확인후 이용가능합니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.erm = null
                        this.$router.replace('/main')
                    } else {
                        // this.$swal({
                        //     title: res.data.msg,
                        //     type: 'error',
                        //     showCancelButton: false,
                        //     showConfirmButton: true
                        // })
                        this.erm = res.data.msg
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            getVerifyPhoneCode() {
                if (this.checkPhone()) {
                    this.$store.commit(RECEIVE_SHOW_LOADING)
                    getVerificationCode(this.user.phone).then(res => {
                        this.$store.commit(RECEIVE_HIDE_LOADING)
                        if (res.data.success) {
                            this.$swal({
                                title: '인증코드 전송되였습니다. 휴대폰 문자를 확인해주세요',
                                type: 'success',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        } else {
                            this.erm = res.data.msg
                        }

                    })
                }
            },
            getVerifyImgCode() {
                getVerificationImgCode().then(res => {
                    if (res.data.success) {
                        console.log(res.data)
                    } else {
                        this.erm = res.data.msg
                    }

                })
            },
            checkPhone() {
                if (!/^[0-9]{11,12}$/.test(this.user.phone)) {
                    this.erm = '휴대폰 번호를 정확하게 입력하세요'
                    return false;
                }
                return true;
            },
            checkUserName() { //회원가입시 아이디 체크
                if (!this.testRegxUserName) {
                    this.error.username = '아이디는 4-15자리 영문,숫자조합입니다'
                    this.status.username = false;
                    return false;
                }
                this.error.username = '';
                return true;
                /*//아이디 사용가능 여부 체크
                this.$store.commit(RECEIVE_SHOW_LOADING)
                checkUserName(this.user.username).then(res => {
                    if (res.data.success) {
                        this.error.username = ''
                        this.status.username = true;
                    } else {
                        this.error.username = res.data.msg
                        this.status.username = false;
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })*/
            },
            checkNickName() { //회원가입시 닉네임 체크
                if (!this.testRegxNickname) {
                    this.error.nickname = '닉네임은 2-6자리 한글만 가능합니다'
                    this.status.nickname = false;
                    return false;
                }
                this.error.nickname = '';
                return true;
                //아이디 사용가능 여부 체크
                /*this.$store.commit(RECEIVE_SHOW_LOADING)
                checkNickName(this.user.nickname).then(res => {
                    if (res.data.success) {
                        this.error.nickname = ''
                        this.status.nickname = true;
                    } else {
                        this.error.nickname = res.data.msg
                        this.status.nickname = false;
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })*/
            },
            checkPasswd() {
                if (!this.testRegxPasswd) {
                    this.error.passwd = '비밀번호는 4-20자리 입니다';
                    this.status.passwd = false;
                    return false;
                }
                this.error.passwd = '';
                this.status.passwd = true;
                return true;
            },
            checkConfirmPassword() {
                if (this.user.confirm_password.trim() === '' || this.user.passwd.trim() !== this.user.confirm_password.trim()) {
                    this.error.confirm_password = '입력하신 비밀번호가 동일하지 않습니다'
                    this.status.confirm_password = false;
                    return false;
                }
                this.error.confirm_password = '';
                this.status.confirm_password = true;
                return true;

            },

            checkBank() {
                if (this.user.bank === '') {
                    this.error.bank = '은행명을 선택하세요'
                    this.status.bank = false;
                    return false;
                }
                this.error.bank = ''
                this.status.bank = true;
                return true;
            },
            checkBeneficiary() {
                if (!/^.{1,10}$/.test(this.user.beneficiary)) {
                    this.error.beneficiary = '예금주를 입력하세요'
                    this.status.beneficiary = false;
                    return false;
                }
                this.error.beneficiary = ''
                this.status.beneficiary = true;
                return true;

            },
            checkAcNo() {
                if (!/^[0-9]{5,20}$/.test(this.user.acNo)) {
                    this.error.acNo = '계좌번호 오류입니다.\'-\'없이 숫자로만 입력하세요'
                    this.status.acNo = false;
                    return false;
                }
                this.error.acNo = '';
                this.status.acNo = true;
                return true;

            },
            checkCode() {
                if (!/^[0-9a-zA-Z]{2,50}$/.test(this.user.code)) {
                    this.error.code = '추천인 코드를 정확하게 입력하세요';
                    this.status.code = false;
                    return false;
                }
                this.error.code = '';
                this.status.code = true;
                return true;
            },


        },
        created() {

            if(!this.$store.state.ischeckedagentcode){
                this.$router.push('/agentcode')
            }

            /*getSiteStatus().then(res=>{
                if(!res.data.success){
                    this.$router.push("/login")
                }
            })*/
            let b = 1949
            for (let i = 1; i <= 71; i++) {
                let bb = b + i;
                this.syear.push(bb)
            }
            for (let i = 1; i <= 12; i++) {
                this.smonth.push(i)
            }
            for (let i = 1; i <= 31; i++) {
                this.sday.push(i)
            }

        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");

    .reg_content {
        width: 100%;
        height: 100vh;
        position: fixed;
        --background: url("../../assets/images/login/ptn/login-bg.jpg") no-repeat center center;
    }

    .reg_panel {
        width: 522px;
        height: 490px;
        position: absolute;
        left: 50%;
        top: 50%;
        background-size: cover;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
        transform: translateX(-50%) translateY(-50%) scale(1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: rgba(0, 142, 138, .8);
        border: 0px solid #88fffb;
        border-image: linear-gradient(to right, #88fffb, #88fffb) 1;
        box-shadow: 0 0 10px #88fffb;
    }
    .crt_panel {
        width: 265px;
        position: absolute;
        left: 50%;
        top: -120px;
        -webkit-transform: translateX(-50%)scale(1);
        transform: translateX(-50%)scale(1);
        z-index: 0;
    }

    .form {
        width: 100%;
        height: 40px;
        padding: 0 5px 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }

    .form .fitem {
        width: 49.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 15px;
    }

    .fitem label {
        padding: 5px 0;
        font-size: 14px;
        color: #cfd1d0;
    }

    .fitem input {
        width: 95%;
        text-align: center;
        height: 32px;
        line-height: 32px;
        color: #fff9ff;
        background-color: #186764;
        border: 1px #186764 solid;
        border-radius:5px;
    }

    .mt {
        margin-top: 25px;
    }

    .err {
        color: #fa6868;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
        animation: sansan 700ms infinite;
        -webkit-animation: sansan 700ms infinite;
    }


    .btn-register {
        height: 32px;
        background: linear-gradient(180deg, #88fffb, #88fffb);
        color: #353535;
        width: 95%;
        font-size: 14px;
        font-weight: bold;
        border-radius: 2px;
        --border: 1px solid #313b45
    }
    .btn-goback {
        height: 32px;
        background: linear-gradient(180deg, #88fffb, #88fffb);
        color: #353535;
        width: 95%;
        font-size: 14px;
        font-weight: bold;
        border-radius: 2px;
        --border: 1px solid #313b45
    }
    .btn-recivehpnecode{
        height: 32px;
        background: linear-gradient(180deg, #88fffb, #88fffb);
        color: #353535;
        width: 95%;
        font-size: 14px;
        font-weight: bold;
        border-radius: 3px;
        --border: 1px solid #313b45
    }

    @media screen and (max-width: 1024px) {
        .reg_panel {
            width: 95% !important;
            height: 490px !important;
            background-size: 100% !important;
            transform: translateX(-50%) translateY(-45%) scale(1) !important;
        }
        .crt_panel {
            top: -110px!important;
        }
    }

    @keyframes sansan {
        from {
            opacity: 1.0;
        }
        70% {
            opacity: 0.8;
        }
        50% {
            opacity: 0.6;
        }
        to {
            opacity: 1.0;
        }
    }

    @-webkit-keyframes sansan {
        from {
            opacity: 1.0;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1.0;
        }
    }
</style>
